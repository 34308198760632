<script>
import { fakeData } from "./fakeData";

export default {
  page: {
    title: "Tenants Table",
  },
  components: {},
  data() {
    return {
      tableData: fakeData,

      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 4,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "tenant_type",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "phone_no",
          label: "Phone No.",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "building",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "unit",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "due_date",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "advance_amount",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "amount_collected",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "old_due",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "total_due",
          sortable: false,  thClass: 'min-width',
        },
        {
          key: "date_of_joining",
          sortable: true,
        },
        {
          key: "tenant_created",
          label: "Completed",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,  thClass: 'min-width',
        },
      ],
    };
  },
  props: ["openDeletePopup", "tenantTableData","rows","setPageNumber", "pageNumber"],
  methods: {
    useOpenDeletePopup(id, index) {
      this.openDeletePopup(id, index);
    },
  },
  watch: {
    currentPage(val) {
      this.setPageNumber(val);
    },
    pageNumber(val) {
      console.log("&&^%", val);
      if(val == 1){
        this.currentPage = val;
      }
    }
  },
};
</script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card" style="overflow:hidden;">
        <div class="card-body" style="position:relative;">
          <h4 class="card-title">List of Tenants {{pageNumber}}</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div>
                <!-- <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label> -->
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="mb-0" style="overflow-x:scroll;">
            <b-table
              :items="tenantTableData ? tenantTableData : []"
              :fields="fields"
              responsive="sm"
              :per-page="0"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
              class="b_table_new"
            >
              <template #cell(actions)="data">
                <div>
                  <i
                    class="fas fa-pencil-alt"
                    style="color: #5b73e8; cursor: pointer"
                    @click="$router.push({ path: '/pages/edit-tenant/' + data.item.id })"
                  ></i>
                  <i
                    class="fas fa-trash-alt"
                    style="color: #f46a6a; cursor: pointer; margin-left: 1rem"
                    @click="useOpenDeletePopup(data.item.id, 1)"
                  ></i>
                </div>
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <img
                    :src="data.item.tenant_image"
                    class="rounded-circle"
                    alt=""
                    style="width: 40px; height: 40px; object-fit: cover"
                  />
                  <span class="mx-2">
                    {{ data.item.name }}
                  </span>
                </div>
              </template>
              <template #cell(tenant_created)="data">
                <div style="width:5rem; height:4px; border-radius:4px; background:#eee; position:relative; overflow:hidden;">
                  <div v-if="data.item.tenant_created < 40" style="height:100%; width:33%; background-color:deepskyblue; position:absolute; top:0;left:0;"></div>
                  <div v-if="data.item.tenant_created >= 40 && data.item.tenant_created < 70" style="height:100%; width:66%; background-color:deepskyblue; position:absolute; top:0;left:0;"></div>
                  <div v-if="data.item.tenant_created >= 70" style="height:100%; width:100%; background-color:deepskyblue; position:absolute; top:0;left:0;"></div>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row" >
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="10"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .min-width { min-width: 300px !important; }
</style>

<style>
  .table_item_width{
    min-width: 200px;
  }
  .b_table_new{
    min-width: 1920px;
  }
</style>