export const fakeData = [
    {
        id: 1,
        name: 'Tenant 1',
        email: 'tenant1@tenant.com',
        unit: 'Unit 1',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 2,
        name: 'Tenant 2',
        email: 'tenant2@tenant.com',
        unit: 'Unit 2',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 3,
        name: 'Tenant 3',
        email: 'tenant3@tenant.com',
        unit: 'Unit 3',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 4,
        name: 'Tenant 4',
        email: 'tenant4@tenant.com',
        unit: 'Unit 4',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 5,
        name: 'Tenant 5',
        email: 'tenant5@tenant.com',
        unit: 'Unit 5',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 6,
        name: 'Tenant 6',
        email: 'tenant6@tenant.com',
        unit: 'Unit 6',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 7,
        name: 'Tenant 7',
        email: 'tenant7@tenant.com',
        unit: 'Unit 7',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 8,
        name: 'Tenant 8',
        email: 'tenant8@tenant.com',
        unit: 'Unit 8',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 9,
        name: 'Tenant 9',
        email: 'tenant9@tenant.com',
        unit: 'Unit 9',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
    {
        id: 10,
        name: 'Tenant 10',
        email: 'tenant10@tenant.com',
        unit: 'Unit 10',
        tenant_image:'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg',
        date_of_joining:"20/1/2022",
    },
]