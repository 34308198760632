<template>
  <Home>
    <Preloader v-if="loading" />
    <div v-if="!isEmpty">
      <button type="button" class="btn btn-info" @click="addTenant">
        <span>+</span> Add Tenant
      </button>
      <button type="file" class="btn btn-pr" v-b-modal.modal-3>
        <span><img src="@/assets/Import_light.png"/></span> Upload Tenants
      </button>

      <a href="@/assets/blank_tenant.csv" download="template.csv"
        ><button class="btn btn-pr">
          <span><img src="@/assets/Import_light.png"/></span>Download Template
        </button></a
      >
      <a href="https://prod.rentnet.in/owners/tenants-export/" download
        ><button class="btn btn-pr1">
          <span><img src="@/assets/Import_light.png"/></span> Export Tenants
        </button></a
      >

      <b-modal
        id="modal-3"
        title="Create Custom Category"
        ok-title="Import"
        @ok="importTenant($event)"
        enctype="multipart/formData"
      >
        <div class="modal-ele">
          <label for="invoice"><strong>Import Tenant</strong></label>
          <input
            type="file"
            ref="file"
            name="invoice"
            id="invoice"
            @change="openFile"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
      </b-modal>

      <div style="width:20rem; margin-top:1rem;">
        <div>
          <label for="invoice"><strong>Select Building</strong></label>
        </div>
        <multiselect
          v-model="selectedBuilding"
          :options="buildingsData"
          track-by="id"
          label="name"
          placeholder="Select one"
          class="helo"
        ></multiselect>
      </div>

      <div class="w-100" v-if="rows > 0">
        <TenantTable
          :rows="rows"
          :openDeletePopup="openDeletePopup"
          :tenantTableData="tenantTableData"
          :setPageNumber="setPageNumber"
          :pageNumber="pageNumber"
        />
      </div>
      <div
        class="
          custom-popup-layout
          d-flex
          justify-content-center
          align-items-center
        "
        v-if="popupId !== 0"
      >
        <div
          class="
            custom-tenant-delete-popup
            d-flex
            flex-column
            justify-content-evenly
          "
        >
          <div class="d-flex flex-column align-items-center">
            <h5>Are you sure you want to delete this Tenant details?</h5>
            <small>Deleted details cannot be recovered.</small>
          </div>
          <div
            class="
              button-container
              d-flex
              align-items-center
              justify-content-between
              w-75
              mx-auto
            "
          >
            <button type="button" class="btn btn-info" style="width: 48%">
              Confirm
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              style="width: 48%"
              @click="closePopups"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEmpty">
      <div style="height:80vh;" class="d-flex flex-column align-items-center justify-content-center">
        <h3 class="mb-3">No Tenants Found</h3>
        <div>
          <button type="button" class="special-button btn btn-info" @click="addTenant">
            <span>+</span> Add Tenant
          </button>
        </div>
      </div>
    </div>
  </Home>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import Home from "../Home/Home.vue";
import TenantTable from "@/new-components/tenant-table/TenantTable.vue";
import { getAllTenants, getallbuilding } from "@/utils/apis.js";
import { impTenants } from "@/utils/apis.js";
// import { expTenant } from "@/utils/apis.js";
import Preloader from "@/new-components/Preloader/Preloader.vue";
import Multiselect from "vue-multiselect";
Vue.use(axios);
export default {
  data() {
    return {
      rows: "",
      loading: true,
      tenantTableData: [],
      popupId: 0,
      selectedDeleteAgent: "",
      file: "",
      files: [],
      isEmpty: false,
      buildingsData: [{ name: "All Buildings", id: "" }],
      selectedBuilding: { name: "All Buildings", id: "" },
      pageNumber: 1,
    };
  },
  methods: {
    openFile() {
      this.file = this.$refs.file.files[0];
    },
    // async exportTenants(){
    //   let res = await expTenant(this.tenantTableData);
    //   console.log(res);
    //   if (res.success) {
    //     this.$toasted.success("Ap ka kaam ho gaya hai", {
    //       position: "top-center",
    //       duration: 3000,
    //     });
    //   } else {
    //     this.$toasted.error("Terese nehi hoga", {
    //       position: "top-center",
    //       duration: 3000,
    //     });
    //   }
    // },
    async importTenant(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", this.file);
      const result = await impTenants(formData);
      console.log(result);
      if (result.success) {
        this.$toasted.success("Imported successfully", {
          position: "top-center",
          duration: 3000,
        });
      } else {
        this.$toasted.error("Bhari mistake ho gaya", {
          position: "top-center",
          duration: 3000,
        });
      }
    },
    async usegetTenants() {
      const body = {
        limit: 10,
        offset: (this.pageNumber - 1) * 10,
      };
      if (this.selectedBuilding.id) {
        body.building = this.selectedBuilding.id;
      }
      const response = await getAllTenants(body);
      this.rows = response.count;
      this.loading = false;
      this.tenantTableData = response.results
        ? this.formatTabledata(response.results)
        : [];
      console.log(response);
      if (response.results.length === 0) {
        this.isEmpty = true;
      }
    },
    formatTabledata(data) {
      let array = [];
      data.forEach((element) => {
        array.push({
          id: element.id,
          name: element.name,
          email: element.email,
          unit: element.unit,
          building: element.building_name ? element.building_name : "N/A",
          date_of_joining: element.date_of_joining,
          tenant_created: element.tenant_created,
          account_name: element.account_name,
          advance_amount: element.advance_amount,
          amount_collected: element.amount_collected,
          bed_id: element.bed_id,
          due_date: element.due_date,
          monthly_rental_plan: element.monthly_rental_plan,
          old_due: element.old_due,
          phone_no: element.phone_no,
          tenant_type: element.tenant_type,
          total_due: element.total_due,
        });
      });
      console.log(array);
      return array;
    },

    async useGetallBuilding() {
      const response = await getallbuilding();
      this.loader = false;
      response.buildings
        ? (this.buildingsData = [
            ...this.buildingsData,
            ...this.formatbuildingsdata(response.buildings),
          ])
        : [];
      console.log(response);
      if (response.buildings.length === 0) {
        this.isEmpty = true;
      }
    },
    formatbuildingsdata(data) {
      let array = [];
      data.forEach((element) => {
        array.push({
          id: element.id,
          name: element.building_name,
          number_of_units: element.no_of_units,
          number_of_tenants: element.owner,
          image: element.image_url,
          total_earnings: element.earnings.amount__sum,
        });
      });
      console.log(array);
      return array;
    },

    addTenant() {
      this.$router.push("/pages/add-tenants");
    },
    openDeletePopup(id, index) {
      this.popupId = index;
      this.selectedDeleteAgent = id;
    },
    closePopups() {
      this.popupId = 0;
      this.selectedDeleteAgent = "";
    },
    setPageNumber(number) {
      this.pageNumber = number;
    },
  },
  mounted() {
    this.usegetTenants();
    this.importTenant();
    this.useGetallBuilding();
  },
  components: {
    Home,
    TenantTable,
    Preloader,
    Multiselect,
  },
  watch: {
    selectedBuilding(newVal) {
      console.log(newVal);
      if (newVal) {
        this.pageNumber = 1;
        this.usegetTenants();
      } else {
        this.usegetTenants();
      }
    },
    pageNumber: function(newVal) {
      console.log(newVal);
      this.usegetTenants();
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-popup-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  backdrop-filter: blur(4px);
  // display: none;
}
.custom-tenant-delete-popup {
  height: 251px;
  width: 613px;
  left: 413px;
  top: 313px;
  border-radius: 20px;
  background-color: #fff;
  max-width: 95%;
}
.btn-pr {
  background-color: transparent;
  border-color: #4cc9f0;
  color: #4cc9f0;
  margin: 5px;
}
.modal-ele {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-pr1 {
  position: absolute;
  left: 1000px;
  top: 20px;

  border-color: #4cc9f0;
  color: #4cc9f0;
}
.btn-pr1:hover {
  background-blend-mode: darken;
}
.special-button {
  height: 4rem;
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
</style>
